<template>
  <div ref="swiper" class="swiper">
    <div v-if="!data.length" class="mt-10">
      <BaseIcon name="NoData"/>
      <div class="font-Regular-12 text-grey">
        {{ $t('dashboard.noData') }}
      </div>
    </div>

    <template v-else>
      <div class="swiper-wrapper custom-swiper-slider">
        <div
          v-for="item in data"
          :key="`${item.type}_${item.coinUnit}_${item.baseUnit}`"
          class="swiper-slide hot-reports-style"
        >
          <div v-if="item.type" class="mb-6 type-title">
            {{ $t(`dashboard.${item.type}`) }}
          </div>

          <div v-if="!item.coinUnit || !item.baseUnit" class="nodata-height text-start">
            <BaseIcon name="NoData"/>
            <div class="font-Regular-12 text-grey pe-6">
              {{ $t('dashboard.noData') }}
            </div>
          </div>

          <router-link tag="div" v-else :to="(`/exchange/${item.coinUnit}_${item.baseUnit}`).toLowerCase()" class="text-white w-100">
            <div class="d-flex mb-4" :class="!item.type && 'mt-6'">
              <div :class="isLocaleLTR ? 'ml-4-prcnt trend-logos-ltr' : 'mr-4-prcnt trend-logos'">
                <div>
                  <img v-if="item.baseIcon" :src="item.baseIcon" height="36" width="36" @error="setDefaultLogo"/>
                  <img v-else :src="require('../../../../src/assets/images/coin_place_holder.png')" height="36" width="36"/>
                </div>

                <div class="logo-in">
                  <img v-if="item.coinIcon" :src="item.coinIcon" height="36" width="36" @error="setDefaultLogo"/>
                  <img v-else :src="require('../../../../src/assets/images/coin_place_holder.png')" height="36" width="36"/>
                </div>
              </div>

              <div class="ms-10">
                <div class="text-grey">
                  {{ item.coinDisplayName }}
                </div>
                <div class="text-grey">
                  {{ item.coinUnit }}/{{ item.baseUnit }}
                </div>
              </div>
            </div>

            <div class="type-title">
              <div
                v-if="item.type !== 'largestVolume'"
                :class="[item.changePercentage === 0 ? 'text-white' : item.changePercentage > 0 ? 'text-green' : 'text-red', isLocaleLTR ? 'mr-2':'ml-2' ]"
                class="font-Medium-14"
                dir="ltr"
              >
                {{ item.changePercentage === 0 ? '' : item.changePercentage > 0 ? '+' : '-' }}{{ Math.abs(item.changePercentage) }}%
              </div>
              <div class="font-Medium-14">
                <template v-if="item.type === 'largestVolume'">
                  <p>{{ numberWithCommas(item.volume, getPairCoinScales[item.coinUnit + '/' + item.baseUnit].frontBaseCoinScale) }}</p>
                </template>

                <template v-else>
                  <p>{{ item.symbolRate | numberWithCommas(getPairCoinScales[item.coinUnit + '/' + item.baseUnit].frontBaseCoinScale) }}</p>
                </template>
              </div>
              <div class="font-Regular-14 ps-2">
                {{ item.baseUnit }}
              </div>
            </div>
          </router-link>
<!--          <Divider v-if="index !== data.length - 1" class="slider-divider" type="vertical"/>-->
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Swiper, {Autoplay} from 'swiper'
import 'swiper/swiper-bundle.css'
import BaseIcon from "@/components/UI/BaseIcon";
import {mapGetters} from "vuex";

export default {
  name: "index",
  components: {
    BaseIcon,
  },
  props: {
    data: {
      type: Array,
      required: true
    },
  },
  computed: {
    ...mapGetters({
      getPairCoinScales: "market/GET_PAIR_COIN_SCALES",
    }),
  },
  data() {
    return {
      swiper: null
    }
  },
  mounted() {
    this.swiper = this.data?.length && new Swiper(this.$refs.swiper, {
      modules: [Autoplay],
      grabCursor: true,
      loop: true,
      autoplay: {
        disableOnInteraction: false,
      },
      breakpoints: {
        100: {
          slidesPerView: 2,
          spaceBetween: 40
        },
        400: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        700: {
          slidesPerView: 3,
        },
      },
    })
  },
  methods: {
    setDefaultLogo(e) {
      e.target.src = require('../../../../src/assets/images/coin_place_holder.png');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "TrendsSlider.scss";
</style>
