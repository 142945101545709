<template>
  <div ref="swiper" class="swiper">
    <div class="text-start">
      {{ $t('dashboard.newestMarkets') }}
    </div>
    <div v-if="data.length <0" class="mt-6">
      <BaseIcon name="NoData"/>
      <div class="font-Regular-12 text-grey">
        {{ $t('dashboard.noData') }}
      </div>
    </div>

    <template v-else>
      <div class="swiper-wrapper">
        <div
            v-for="(item, index) in data"
            :key="`${index}_${item.coinUnit}_${item.baseUnit}`"
            class="swiper-slide"
        >
          <div v-if="!item.coinUnit || !item.baseUnit" class="nodata-height text-start">
            <BaseIcon name="NoData"/>
            <div class="font-Regular-12 text-grey pl-6">
              {{ $t('dashboard.noData') }}
            </div>
          </div>

          <router-link tag="div" v-else :to="(`/exchange/${item.coinUnit}_${item.baseUnit}`).toLowerCase()"
                       class="text-white w-100">
            <div class="d-flex justify-around mt-6 mb-4">
              <div :class="isLocaleLTR ? 'trend-logos-ltr me-8' : 'trend-logos ms-10'">
                <div>
                  <img v-if="item.baseIcon" :src="item.baseIcon" height="36" width="36" @error="setDefaultLogo"/>
                  <img v-else :src="require('../../../../src/assets/images/coin_place_holder.png')" height="36" width="36"/>
                </div>

                <div class="logo-in">
                  <img v-if="item.coinIcon" :src="item.coinIcon" height="36" width="36" @error="setDefaultLogo"/>
                  <img v-else :src="require('../../../../src/assets/images/coin_place_holder.png')" height="36" width="36"/>
                </div>
              </div>

              <div class="mr-8">
                <div class="text-grey">
                  {{ item.coinDisplayName }}
                </div>
                <div class="text-grey">
                  {{ item.coinUnit }}/{{ item.baseUnit }}
                </div>
              </div>
            </div>

            <div class="type-title ms-10">
              <div class="font-Medium-14">
                <p>{{
                    item.symbolRate | numberWithCommas(getPairCoinScales[item.coinUnit + '/' + item.baseUnit].frontBaseCoinScale)
                  }}</p>
              </div>
              <div class="font-Regular-14 ps-2">
                {{ item.baseUnit }}
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <div v-if="data.length > 1">
        <div class="swiper-button-prev" :class="isLocaleLTR && 'ltr-swiper-button-prev'"></div>
        <div class="swiper-button-next" :class="isLocaleLTR && 'ltr-swiper-button-next'"></div>
      </div>
    </template>
  </div>
</template>

<script>
import Swiper, {Navigation, Autoplay} from 'swiper'
import 'swiper/swiper-bundle.css'
import BaseIcon from "@/components/UI/BaseIcon";
import {mapGetters} from "vuex";

export default {
  name: "index",
  components: {
    BaseIcon,
  },
  props: {
    data: {
      type: Array,
      required: true
    },
  },
  computed: {
    ...mapGetters({
      getPairCoinScales: "market/GET_PAIR_COIN_SCALES",
    }),
  },
  data() {
    return {
      swiper: null
    }
  },
  mounted() {
    this.swiper = this.data?.length && new Swiper(this.$refs.swiper, {
      modules: [Navigation, Autoplay],
      grabCursor: true,
      loop: true,
      autoplay: {
        disableOnInteraction: false,
      },
      breakpoints: {
        100: {
          slidesPerView: 2,
          spaceBetween: 30
        },
        700: {
          slidesPerView: 3,
          spaceBetween: 30
        },
      },
      navigation: {
        nextEl: '.swiper-button-prev',
        prevEl: '.swiper-button-next',
      },
    })
  },
  methods: {
    setDefaultLogo(e) {
      e.target.src = require('../../../../src/assets/images/coin_place_holder.png');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "NewestSlider";
</style>
