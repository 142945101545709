<template>
  <Row :gutter="10" class="dashboard-trends mb-4">
    <Col :lg="16" :span="24">
      <Card class="mb-6" dis-hover>
        <div v-if="loading" class="mt-16">
          <Loader/>
        </div>

        <TrendsSlider v-else :data="hotReports"/>
      </Card>
    </Col>

    <Col :lg="8" :span="24" class="latest-trend">
      <Card dis-hover>
        <div v-if="loading" class="mt-16">
          <Loader/>
        </div>

        <template v-else>
          <NewestSlider :data="newestMarkets"/>
        </template>
      </Card>
    </Col>
  </Row>
</template>

<script>
import TrendsSlider from "../TrendsSlider"
import NewestSlider from "../NewestSlider"
import Loader from "@/components/UI/LoadingComp"

export default {
  name: "index",
  components: {
    TrendsSlider,
    NewestSlider,
    Loader
  },
  props: {
    hotReports: {
      type: Array,
      default() {
        return []
      }
    },
    newestMarkets: {
      type: Array,
      default() {
        return []
      }
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    setDefaultLogo(e) {
      e.target.src = require('../../../../src/assets/images/coin_place_holder.png');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "TrendsSection.scss";
</style>